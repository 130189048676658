import transactionsService from "../../../../services/transactionsService";
import $formatNumber from "./../../../../mixins/formatNumber";

export default {
    name: "ether-wallet-management",
    components: {},
    props: [],
    mixins: [$formatNumber],
    data() {
        return {
            currentPage: 1,
            tableHeaders: ["نام ارز", "آدرس", "عملیات"],
            address: "",
        };
    },
    computed: {},
    mounted() {
        this.getEtherAddressFunc();
    },
    methods: {
        getEtherAddressFunc() {
            transactionsService
                .getAdminEtherAddresses(
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then((response) => {
                    this.address = response.data;
                })
                .catch((error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
        toggleEtherStatusAddress(id, status) {
            status = !status;
            transactionsService
                .toggleStatusAdminEtherAddresses(
                    id,
                    status,
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then(() => {
                    this.getEtherAddressFunc();
                });
        },
        addEtherStatusAddress() {
            transactionsService
                .addEtherAdminAddresses(
                    localStorage.getItem("bitrahAdminAccessToken")
                )
                .then(() => {
                    this.getEtherAddressFunc();
                })
                .catch((err) => {
                    this.$bvToast.toast(err.data.message, {
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
    },
};
